<template>
  <form @submit.prevent="onSubmit()">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__header">
        <Pagination />
        <Btn v-if="helperService.userHasPermission('protocole_add')
        " class="action-btn" icon="plus" color="primary" text="Créer un protocole" :to="{
          name: 'protocolGeneralInformationAdd',
        }" />
      </div>
      <div class="view-header__footer">
        <h1 class="page-title" v-html="pageTitle"></h1>
      </div>
    </div>
    <div class="view-body">
      <Section class="section section--list">
        <Search :key="componentKey" v-model="search" returnObject :searchEndpoint="list.api.endpoint"
          searchPlaceholder="Rechercher un protocole">
          <template v-slot:suggestions="{ item }">
            <router-link :to="{ name: 'protocol', params: { id: item.id } }">
              {{ item.titre }}
            </router-link>
          </template>

          <SelectExtended id="thematique" v-model="search.filters.thematique.values" multiple search label="Thématiques"
            optionKey="id" optionValue="designation" apiEndpoint="dictionnaire/thematique" />

          <SelectExtended id="culture" v-model="search.filters.culture.values" multiple search label="Cultures"
            optionKey="id" optionValue="nom" apiEndpoint="culture" :apiParams="{ limit: 0, sort: 'nom.ASC' }" />

          <SelectExtended id="type" v-model="search.filters.type.values" multiple search label="Type" optionKey="id"
            optionValue="designation" apiEndpoint="dictionnaire/type/protocole" />

          <SelectExtended id="statut" v-model="search.filters.statut.values" multiple search label="Statut"
            optionKey="id" optionValue="designation" apiEndpoint="dictionnaire/statut/protocole" />

          <SelectExtended id="annee" v-model="search.filters.annee.values" multiple search label="Année" optionKey="id"
            optionValue="valeur" :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
              'anneerecolte',
            )}/valeur`" />

          <Radio v-model="search.filters.utilisation_phyto.values" id="utilisation_phyto" :items="[
            { label: 'oui', value: true },
            { label: 'non', value: false },
          ]" :label="search.filters.utilisation_phyto.label" inputStyle="inline" />

          <Radio v-model="search.filters.prestation_service.values" id="prestation_service" :items="[
            { label: 'oui', value: true },
            { label: 'non', value: false },
          ]" :label="search.filters.prestation_service.label" inputStyle="inline" />

          <Radio v-model="search.filters.contamination_artificielle.values" id="contamination_artificielle" :items="[
            { label: 'oui', value: true },
            { label: 'non', value: false },
          ]" :label="search.filters.contamination_artificielle.label" inputStyle="inline" />

          <Radio v-model="search.filters.brumisation.values" id="brumisation" :items="[
            { label: 'oui', value: true },
            { label: 'non', value: false },
          ]" :label="search.filters.brumisation.label" inputStyle="inline" />

          <Radio v-model="search.filters.bpe.values" id="bpe" :items="[
            { label: 'oui', value: true },
            { label: 'non', value: false },
          ]" :label="search.filters.bpe.label" inputStyle="inline" />

          <Radio v-model="search.filters.agriculture_biologique.values" id="agricuture_biologique" :items="[
            { label: 'oui', value: true },
            { label: 'non', value: false },
          ]" :label="search.filters.agriculture_biologique.label" inputStyle="inline" />

          <Radio v-model="search.filters.partage_accepte.values" id="partage_accepte" :items="[
            { label: 'oui', value: true },
            { label: 'non', value: false },
          ]" :label="search.filters.partage_accepte.label" inputStyle="inline" />

        </Search>

        <List v-if="filterSeason?.filters" :api="list.api" :header="list.headers" :items="list.col" :item-key="list.key"
          :target="list.target" :defaultFilter="filterSeason" :disableChangesOn="immutablesProtocoles">
          <template v-slot:group-actions="{ selectedItems }">
            <Btn text="Supprimer" icon="trash-alt" color="default" @click="openModal('deleteItems', selectedItems)" />
          </template>

          <template v-slot:titre="{ item }">

            <div class="essai-bpe" v-if="item.bpe">
              <div>
                {{ item.titre }}
              </div>
              <div class="essai-bpe-icon">
                <SvgIcon name="bpe" size="lg" />
              </div>
            </div>
            <div v-else>
              {{ item.titre }}
            </div>
          </template>

          <template v-slot:annee_recolte="{ item }">
            {{ item.annee_recolte?.valeur }}
          </template>

          <template v-slot:cultures="{ item }">
            <template v-if="item.cultures.length > 0">
              <div class="tags">
                <MiniTag v-for="culture in item.cultures" :key="culture.id" :id="culture.uid" :text="culture.nom" />
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:thematique="{ item }">
            {{ item.thematique?.designation }}
          </template>

          <template v-slot:sous_thematiques="{ item }">
            <template v-if="item.sous_thematiques.length > 0">
              <div class="tags">
                <MiniTag v-for="sous_thematique in item.sous_thematiques" :key="sous_thematique.id"
                  :id="sous_thematique.uid" :text="sous_thematique.nom" />
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:statut="{ item }">
            {{ item.statut?.designation }}
          </template>

          <template v-slot:date_partage="{ item }">
            {{ item?.date_partage ? 'Oui' : 'Non' }}
          </template>

          <template v-slot:actions="{ item }">
            <ToolDropdown>
              <MenuItem v-if="helperService.userHasPermission('protocole_show')" :item-data="{
                label: 'Consulter',
                iconName: 'visibility',
                route: {
                  name: 'protocol',
                  params: {
                    id: item.id,
                  },
                },
              }" />
              <MenuItem v-if="item.statut?.uid !== 'ENCOURS'
                && helperService.userHasPermission(
                  'protocole_edit,protocole_remove', 'OR'
                )" :item-data="{
                  label: 'Éditer/supprimer',
                  iconName: 'create',
                  route: {
                    name: 'protocolGeneralInformationEdit',
                    params: {
                      id: item.id,
                    },
                  },
                }" />
              <MenuItem v-if="helperService.userHasPermission(
                'protocole_duplicate'
              )" :item-data="{
                label: 'Dupliquer',
                iconName: 'clone',
              }" @click="duplicate(item)" />
              <MenuItem v-if="helperService.userHasPermission(`log_list`)" :item-data="{
                label: 'Voir les logs',
                route: {
                  name: 'ressourceLogs',
                  params: {
                    ressource: 'protocol',
                    rid: 'protocol',
                    ressourceid: item.id,
                    from: 'protocols',
                    title: `Voir les logs`,
                  },
                },
                iconName: 'clipboard-list',
              }" />
              <MenuItem :item-data="{
                label: 'Export de la fiche protocole',
                iconName: 'share-alt',
              }" @click="downloadFile('pdf', 'protocole', item.id)" />
              <MenuItem :item-data="{
                label: 'Export des modalités',
                iconName: 'file-excel',
              }" @click="downloadFile('xls', 'protocole', item.id, 'modalites')" />
              <MenuItem v-if="item.statut?.uid !== 'BROUILLON'
                && helperService.userHasPermission('protocole_children')" :item-data="{
                  label: 'Partager',
                  iconName: 'share',
                  route: {
                    name: 'protocolModalities',
                    params: {
                      id: item.id,
                    },
                  },
                }" />
              <MenuItem v-if="helperService.userHasPermission('protocolefichier_edit,fichier_add')" :item-data="{
                label: 'Ajouter un fichier',
                iconName: 'plus',
                route: {
                  name: 'protocolFiles',
                  params: {
                    id: item.id,
                  },
                },
              }" />
            </ToolDropdown>
          </template>
        </List>
      </Section>
    </div>
  </form>

  <!-- Modals -->
  <!-- Groupe d'actions de suppression -->
  <Modal title="Supprimer des protocoles&nbsp;?" :active="modal.deleteItems" :data="modalData"
    @modal-close="modal.deleteItems = false">
    <template v-slot:modal-body="{ data }">
      <p v-if="modal.partage == 1"><b class="color-warning">Attention, un protocole a été proposé au partage.</b></p>
      <p v-if="modal.partage > 1"><b class="color-warning">Attention, {{ modal.partage }} protocoles ont été proposés au
          partage.</b></p>
      <p>
        Voulez vous vraiment supprimer
        {{ data.length }} protocoles&nbsp;?
      </p>
    </template>
    <template v-slot:modal-footer="{ data }">
      <Btn text="Annuler" @click="modal.deleteItems = false" />
      <Btn text="Supprimer" @click="deleteItems(data)" icon="trash-alt" color="primary" />
    </template>
  </Modal>
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import List from '@/components/list/List.vue'
import Pagination from '@/components/list/Pagination.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Search from '@/components/list/Search.vue'
import Modal from '@/components/layout/Modal.vue'
import ToolDropdown from '@/components/layout/ToolDropdown.vue'
import MenuItem from '@/components/layout/MenuItem.vue'
import Radio from '@/components/form/Radio.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'

export default {
  name: 'ProtocolsView',

  components: {
    Radio,
    MenuItem,
    ToolDropdown,
    Modal,
    Search,
    SelectExtended,
    Pagination,
    List,
    Btn,
    Section,
    MiniTag,
    SvgIcon,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      filterSeason: {},
      immutablesProtocoles: null,
      search: {
        attributs: ['titre'],
        filters: {
          // Object
          culture: {
            type: 'object',
            key: 'id',
            values: [],
            label: 'Cultures',
          },
          statut: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Statut',
          },
          thematique: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Thématique',
          },
          type: {
            type: 'object',
            values: [],
            key: 'id',
            label: 'Type',
          },
          annee: {
            type: 'relation',
            values: [],
            key: 'id',
            label: 'Année',
          },

          // Booléan
          utilisation_phyto: {
            type: 'boolean',
            values: [],
            label: 'Utilisation phytosanitaire',
          },
          prestation_service: {
            type: 'boolean',
            values: [],
            label: 'Prestation de service',
          },
          contamination_artificielle: {
            type: 'boolean',
            values: [],
            label: 'Contamination artificielle',
          },
          brumisation: {
            type: 'boolean',
            values: [],
            label: 'Brumisation',
          },
          bpe: {
            type: 'boolean',
            values: [],
            label: 'BPE',
          },
          agriculture_biologique: {
            type: 'boolean',
            values: [],
            label: 'Agriculture biologique',
          },
          partage_accepte: {
            type: 'boolean',
            values: [],
            label: 'Partage accepté',
          },
        },
      },

      list: {
        target: {
          route: {
            name: 'protocol',
            paramsFromRoute: undefined,
            itemParams: { id: 'id', from: 'fkro' },
          },
        },
        api: {
          endpoint: 'protocole',
          params: {
            sort: 'titre.ASC',
            page: null,
            limit: null,
            filters: null,
          },
        },
        headers: [
          { label: 'Titre', dataField: 'titre', isLink: true },
          { label: 'Code national', dataField: 'code_national', hidden: 'mobile' },
          { label: 'Code régional', dataField: 'code_regional', hidden: 'mobile' },
          { label: 'Code entité', dataField: 'code_entite', hidden: 'mobile' },
          { label: 'Année' },
          { label: 'Cultures' },
          { label: 'Thématique', hidden: 'mobile' },
          { label: 'Sous-thématiques', hidden: 'tablet' },
          { label: 'Issue de partage', dataField: 'date_partage', hidden: 'tablet' },
          { label: 'Statut', hidden: 'tablet' },
        ],
        col: [
          'titre',
          'code_national',
          'code_regional',
          'code_entite',
          'annee_recolte',
          'cultures',
          'thematique',
          'sous_thematiques',
          'date_partage',
          'statut',
        ],
        key: 'id',
      },

      modalData: {},
      modal: {
        deleteItems: false,
        protocolChoice: false,
        partage: 0,
      },
      componentKey: 0,
    }
  },

  watch: {
    '$route.name': function (value) {
      this.search.filters = {
        // Object
        culture: {
          type: 'object',
          key: 'id',
          values: [],
          label: 'Cultures',
        },
        statut: {
          type: 'object',
          values: [],
          key: 'id',
          label: 'Statut',
        },
        thematique: {
          type: 'object',
          values: [],
          key: 'id',
          label: 'Thématique',
        },
        type: {
          type: 'object',
          values: [],
          key: 'id',
          label: 'Type',
        },

        // Booléan
        utilisation_phyto: {
          type: 'boolean',
          values: [],
          label: 'Utilisation phytosanitaire',
        },
        prestation_service: {
          type: 'boolean',
          values: [],
          label: 'Prestation de service',
        },
        contamination_artificielle: {
          type: 'boolean',
          values: [],
          label: 'Contamination artificielle',
        },
        brumisation: {
          type: 'boolean',
          values: [],
          label: 'Brumisation',
        },
        bpe: {
          type: 'boolean',
          values: [],
          label: 'BPE',
        },
        agriculture_biologique: {
          type: 'boolean',
          values: [],
          label: 'Agriculture biologique',
        },
        partage_accepte: {
          type: 'boolean',
          values: [],
          label: 'Partage accepté',
        },
      }
      if (value === 'protocolsShare') {
        this.search.filters = {}
      }
      this.componentKey += 1
    },
  },

  // If the user reload the page
  mounted() {
    this.filterSeason = this.protocolService.filterByAgriculturalSeason('id', true)
    this.search.filters.annee.values = this.filterSeason.years
    this.emitter.emit('list-update-active-filters')

    this.fetchService.get('protocole').then((res) => {
      if (res.data.length > 0) {
        const protocoles = res.data
        this.immutablesProtocoles = protocoles
          .filter((p) => p.statut?.uid === 'ENCOURS')
          .map((p) => p.id)
      }
    })
  },

  methods: {
    duplicate(protocole) {
      this.emitter.emit('open-loader')
      this.fetchService.get(`protocole/${protocole.id}/modalite`, {
        limit: 0,
        sort: 'ordre.ASC',
      }).then(
        (response) => {
          const modalites = response.data
          const postParams = {}
          postParams.modalites = modalites.map(
            (item) => ({ id: item.id }),
          )
          this.fetchService.patch(`protocole/${protocole.id}/dupliquer`, postParams).then(
            (res) => {
              if (res.meta.success) {
                this.emitter.emit('alert', {
                  type: 'success',
                  content:
                    'Le protocole a bien été dupliqué et apparait désormais dans la liste de vos protocoles actifs.',
                })
              }
              this.emitter.emit('list-refresh')
              this.emitter.emit('close-loader')
            },
          )
        },
      )
    },

    async openModal(modal, data) {
      if (Array.isArray(data)) {
        this.modalData = data.filter((item) => item !== null)
      } else {
        this.modalData = data
      }

      if (modal === 'deleteItems') {
        const arrayPromise = []
        data.forEach((item) => {
          arrayPromise.push(this.fetchService.get(`protocole/${item.id}/enfants`))
        })

        Promise.all(arrayPromise).then((responses) => {
          responses.forEach((response) => {
            this.modal.partage += (response.meta.pagination.count > 0) ? 1 : 0
          })
          this.modal[modal] = true
        })
      } else {
        this.modal[modal] = true
      }
    },

    downloadFile(type, ressource, rid, childRessource = null) {
      let endpoint = `taches/type/${type}/ressource/${ressource}/${rid}`

      if (childRessource) {
        endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/${childRessource}`
      }

      const filename = childRessource
        ? `${ressource}_${rid}_${childRessource}`
        : `${ressource}_${rid}`

      this.fileService.downloadFile(endpoint, filename, type)
    },

    async deleteItems(itemsToDelete) {
      this.emitter.emit('open-loader')

      await itemsToDelete.forEach((item) => {
        if (this.immutablesProtocoles.includes(item.id)) {
          this.emitter.emit('alert', {
            type: 'error',
            content: `Impossible de supprimer le protocole ${item.titre} : en cours d'utilisation.`,
          })
        } else {
          this.fetchService.delete(`protocole/${item.id}`).then(
            () => {
              this.emitter.emit('alert', {
                type: 'success',
                content: `Le protocole ${item.titre} a bien été supprimé.`,
              })

              this.emitter.emit('list-refresh')
            },
            (responseError) => {
              this.emitter.emit('alert', {
                type: 'error',
                content: responseError.data,
              })
            },
          )
        }
      })
      this.emitter.emit('list-refresh')

      this.emitter.emit('close-loader')
      this.modal.deleteItems = false
    },
  },
}
</script>

<style scoped></style>
